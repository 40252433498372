<template>
  <base-layout>
    <div class="privateAgency">
      <!-- MAIN PART -->
      <div class="main" style="padding-left: 20px; padding-right: 20px">
        <div class="row">
          <div class="right col-12 col-md-5" v-if="!showdetail">
            <div class="card2">
              <h4>Ticket</h4>
              <div class="form-group">
                <label for="">Subject</label>
                <select class="form-control" v-model="ticket.subject">
                  <option
                    :value="list.ticket_subject_id"
                    v-for="list in ListSubject"
                    :key="list.id"
                  >
                    {{
                      list.ticket_subject_name
                        .toLowerCase()
                        .split(" ")
                        .join("_")
                    }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="">Detail (*)</label>
                <textarea rows="5" class="form-control" v-model="ticket.content"></textarea>
              </div>

              <div class="form-group text-center">
                <button class="btn btn-success" @click="sendTicket">
                  Send
                </button>
              </div>
            </div>
          </div>
          <div v-else class="mx-auto col-12 col-md-5">
            <DetailTicket
              :ID="ticketDetails.id"
              :Subject="ticketDetails.subject"
              :SubjectID="ticketDetails.SubjectID"
            />
          </div>
          <div class="right col-12 col-md-7">
            <div class="card2">
              <div class="tableWrapper table-responsive">
                <table class="table mb-0">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Subject</th>
                      <th>Status</th>
                      <th>Time</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="list in ListTicket" :key="list.ticket_Time">
                      <td>{{ list.ticket_ID }}</td>
                      <td>{{ list.ticket_subject_name }}</td>
                      <td>
                        <span
                          :style="
                            list.ticket_Status == 'Waiting'
                              ? 'padding:5px 10px;font-size:14px;background: yellow;color:#000'
                              : 'padding:5px 10px;font-size:14px;background: green'
                          "
                          >{{ list.ticket_Status }}</span
                        >
                      </td>
                      <td>{{ list.ticket_Time }}</td>
                      <td>
                        <a
                          class="btn btn-success btn-sm"
                          @click="
                            showDetail(
                              list.ticket_ID,
                              list.ticket_subject_name,
                              list.ticket_subject_id
                            )
                          "
                          >Detail</a
                        >
                      </td>
                    </tr>
                    <tr v-if="!ListTicket">
                      <td colspan="6" class="text-center">
                        No Data
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </base-layout>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import DetailTicket from "@/components/DetailTicket.vue";
import BaseLayout from "./layouts/Base";

export default {
  components: {
    DetailTicket,
    BaseLayout
  },
  data() {
    return {
      inviteCode: "32A1265",
      ticket: {
        content: "",
        subject: 1,
        token: ""
      },
      ticketDetails: {
        id: null,
        subject: null,
        SubjectID: null
      },
      showdetail: false
    };
  },
  computed: {
    ...mapGetters({
      ListSubject: "ticket/ListSubject",
      ListTicket: "ticket/ListTicket"
    })
  },
  methods: {
    onCopy: function(e) {
      alert("You just copied: " + e.value);
    },
    sendTicket: async function() {
      this.$store.dispatch("ticket/req_postSubject", this.ticket);
    },
    showDetail(id, subject, SubjectID) {
      this.ticketDetails.id = id;
      this.ticketDetails.subject = subject;
      this.ticketDetails.SubjectID = SubjectID;
      if (id === undefined || id === null) {
        this.$toastr.e("Please Reload Page And Chose Ticket Detail Again", "Something Wrong");
        return;
      } else {
        this.showdetail = false;
        this.$nextTick(() => {
          this.showdetail = true;
        });
      }
    }
  },
  created() {
    this.$store.dispatch("ticket/req_getTicket");

    let self = this;
    this.unsubscribe = this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case "HiddenChat":
          self.ticketDetails = {
            id: null,
            subject: null,
            SubjectID: null
          };
          self.showdetail = false;
          break;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  async mounted() {
    // simulate load data
  }
};
</script>

<style lang="scss" scoped>
textarea.form-control {
  background-color: transparent !important;
  color: #fff !important;
}

.table thead th {
  vertical-align: middle;
  // border-bottom: 1px solid $primaryColor;
  // border-top: 1px solid $primaryColor;
}
.main {
  min-height: calc(100vh - 100px);
  padding: 50px 0;

  .left {
    margin-bottom: 30px;

    .title {
      font-size: 2em;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .button {
      font-size: 1.5em;
      font-weight: bold;
    }
  }

  .right {
    .card2 {
      background-color: rgba(0, 0, 0, 0.3);
      border: 1px solid;
      border-radius: 20px;
      padding: 30px 20px;
      color: white;
    }

    .inputGroup {
      border: 1px solid;
      border-radius: 5px;
      padding: 5px;
      display: flex;
      overflow: hidden;

      input {
        border: none;
        background-color: transparent;
        color: white;
        width: calc(100% - 80px);
      }
      button {
        width: 80px;
        border-radius: 5px;
      }

      input:active,
      input:focus {
        border: none;
        outline: none;
      }
    }
  }
}

.footer {
  background-color: #2b2f42;
  padding: 20px 0;

  .box {
    margin: 20px 0;

    .icon {
      border: 1px solid;
      width: 60px;
      height: 60px;
      padding: 10px;
      border-radius: 30px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .info {
      width: calc(100% - 80px);
      margin-left: 10px;

      .name {
        font-size: 1.1em;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
}

/*** RESPONSIVE ON PC ***/
@media (min-width: 992px) {
  .main {
    .left {
      .title {
        font-size: 2.5em;
      }
    }
  }
}
</style>
